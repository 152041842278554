import { HStack, VStack } from "@chakra-ui/react"
import React from "react"
import { useContext } from "react"
import { useState } from "react"
import { ApiContext } from "../../user_wrapper"
import Wrapper from "../wrapper"
import ZiptiButton from "../zipti_button"
import ZiptiInput from "../zipti_input"
import ZiptiText from "../zipti_text"
import { navigate } from "gatsby-link"
import { useEffect } from "react"
import { getIdFromUrl } from "../../../services/utils"

interface UpdateEmailConfirmationProps {}

const UpdateEmailConfirmation: React.FC<UpdateEmailConfirmationProps> = props => {
  const apiClient = useContext(ApiContext)

  const updateEmailUseEffect = async () => {
    const confirmation_code = getIdFromUrl("update_email_confirmation")
    if (!confirmation_code) return
    const res = await apiClient.sendUpdateEmailConfirmation(confirmation_code)
  }

  useEffect(() => {
    updateEmailUseEffect()
  }, [])

  return (
    <Wrapper>
      <ZiptiText mt="8rem">Your Email Has Been Confirmed!</ZiptiText>
      <VStack>
        <ZiptiButton
          w="12rem"
          mt="2rem"
          onClick={() => navigate("/edit_profile")}
        >
          Return to edit profile
        </ZiptiButton>
        <ZiptiButton w="12rem" mt="2rem" onClick={() => navigate("/home")}>
          Go to homepage
        </ZiptiButton>
      </VStack>
    </Wrapper>
  )
}

export default UpdateEmailConfirmation
